<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>CMS模块</el-breadcrumb-item>
            <el-breadcrumb-item>文章分类</el-breadcrumb-item>
            <el-breadcrumb-item>编辑分类</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号">
          <el-input v-model.number="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" :error="$v.form.pId.$error ? '请输入选择所属分类' : ''">
          <el-cascader :options="classify" :props="{ checkStrictly: true }" clearable v-model="form.pId" @change="changeClassify"></el-cascader>
        </el-form-item>
        <el-form-item label="分类名称" :error="$v.form.name.$error ? '请输入分类名称' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="缩略图">
          <image-upload @upload="imageUpload" :image="form.img" />
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="form.keyWord"></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <el-input v-model="form.tag"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.describe"></el-input>
        </el-form-item>

        <el-form-item label="类型">
          <el-radio-group v-model="form.iType">
            <el-radio :label=1>列表</el-radio>
            <el-radio :label=2>单页</el-radio>
            <el-radio :label=3>分类</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label=1>启用</el-radio>
            <el-radio :label=0>禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from '@/components/tinymce'
import ImageUpload from '@/components/imageUpload'
import { edittypeRequest, getSelectRequest, getdatilRequest } from '@/api/classify'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'Articletypecreate',
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        img: '',
        pId: '',
        name: '',
        keyWord: '',
        tag: '',
        describe: '',
        states: 1,
        addDate: '',
        sort: 0,
        iType: 1,
        content: '',
      },
    }
  },
  components: {
    'image-upload': ImageUpload,
    tinymce: Tinymce,
  },
  created() {
    this.form.id = this.$route.params.id
    this.getSelect()
    this.getdetail()
  },
  methods: {
    back() {
      this.$router.back()
    },
    changeClassify(e) {
      this.form.pId = e[e.length - 1]
    },
    getSelect() {
      getSelectRequest().then((res) => {
        console.log(res)
        this.classify = res.data
      })
    },
    getdetail() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        console.log(res)
        this.form = res.data
      })
    },
    imageUpload(url) {
      this.form.img = url
    },
    save() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        edittypeRequest(this.form).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      pId: {
        required,
      },
      
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
